<template>
  <div>
    <GenericForm
      title="setores"
      previousRoute="setor"
      editRoute="setorEditar"
      :model="model"
      :route="setorRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_setor"
      :permissionsToWrite="['rw_unidade']"
      :permissionsToEdit="['re_unidade']"
      :permissionsToDelete="['rd_unidade']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Nome*" label-for="nome">
              <b-form-input
                id="nome"
                v-model="model.nome"
                autocomplete="off"
                class="invision-input"
                name="nome"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('nome')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Descrição" label-for="descricao">
              <b-form-input
                id="descricao"
                v-model="model.descricao"
                autocomplete="off"
                class="invision-input"
                name="descricao"
                :disabled="isBusy"
                v-validate="{ required: false }"
                :state="validateState('descricao')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Permitir acesso as Unidades:*" label-for="unidade">
                  <GenericMultipleSelect
                    name="unidade"
                    v-validate="{ required: true }"
                    ref="unidade"
                    labelKey="nome"
                    :value="model.unidades.map((u) => ({ value: u.id_unidade, label: u.nome }))"
                    route="unidade"
                    @input="state=>changeSetor(model, state)"
                  ></GenericMultipleSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import GenericForm from '@/components/Form/GenericForm';
import GenericMultipleSelect from '@/components/Form/GenericMultipleSelect';

export default {
  components: {
    GenericForm,
    GenericMultipleSelect,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_setor: this.$route.params.id || -1,
        nome: '',
        descricao: '',
        unidades: [],
      },

      setorRoute: 'setor',
    };
  },

  methods: {
    updateModel(model) {
      this.model = model;
    },

    async changeSetor(setor, value) {
      setor.unidades = value.map((v) => ({
        id_unidade: v.value,
        nome: v.label,
      }));
    },
  },
};
</script>
